<template>
<div class="container" @mouseenter="tagText = 'gjort av willeponk'" @mouseleave="tagText = defaultTagText">{{ tagText }}</div>
<pre class="logo">
    {{ logoText }}
</pre>

</template>

<script setup>
    import { ref } from 'vue';
    const defaultTagText = "gnomehunters.online (r)"
    let tagText = ref(defaultTagText)

    const logoList = [
        require('raw-loader!../assets/logos/2.txt')
        // Add more file paths as needed
    ];

    const randomLogoIndex = Math.floor(Math.random() * logoList.length);
    const logo = logoList[randomLogoIndex];
    let logoText = ref(logo.default);  
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo{
    font-size: 12px;
    margin-top: -10px;
}

.container{
    font-size: 10px;
    padding-top: 5px;
}

@media screen and (max-width: 500px) {
  .container {
    font-size: 5px;
  }

  .logo{
    font-size: 7px;
  }
}
</style>
