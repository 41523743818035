<template>
  <router-view/>
</template>

<style>
body{
  background-color: black;
  color: #00ff41;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;

  margin: 0;
  box-sizing: border-box;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

</style>
